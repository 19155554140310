<template>
  <div class="bg-expandable" @mousewheel="closeActions()">
    <Tabs :tabs="tabs" :active-tab.sync="activeTab" start-space="35px" />
    <Table
      v-if="activeTab === 0"
      v-loading="loading"
      :columns="orderColumns"
      :data="orderData"
      custom-class="activity-table orders"
      show-index
      expandable
      small
      @row-click="handleRowClick"
    >
      <template #[`cell-${ORDER_TABLE_HEADER.DATE}`]="{ rowData: { [ORDER_TABLE_HEADER.DATE]: date } }">
        <p>{{ formatDate(date) }}</p>
      </template>
      <template #[`cell-${ORDER_TABLE_HEADER.DOCUMENTS}`]="{ rowData: { [ORDER_TABLE_HEADER.DOCUMENTS]: documents } }">
        <div v-if="documents.length" class="d-flex flex-column align-items-start">
          <Button
            v-for="doc in documents"
            :key="doc.id"
            type="link"
            class="p-0 m-0"
            @click.stop="$emit('open-document', { documentId: doc.id, documentsIds: getDocumentsIds(orders) })"
          >
            {{ $t(`document.exports.schema.type.shortName.${doc.type}`) }} {{ doc.documentNumber }}
          </Button>
        </div>
        <span v-else>{{ $t('billing.billingManagement.expandableActivity.balanceOrder') }}</span>
      </template>
      <template
        #[`cell-${ORDER_TABLE_HEADER.PRODUCT_COUNT}`]="{
          rowData: { [ORDER_TABLE_HEADER.PRODUCT_COUNT]: productCount },
        }"
      >
        <p>{{ productCount ? productCount : '-' }}</p>
      </template>
      <template
        #[`cell-${ORDER_TABLE_HEADER.TOTAL_NET_AMOUNT}`]="{
          rowData: { [ORDER_TABLE_HEADER.TOTAL_NET_AMOUNT]: amount, order, hasDifferences },
        }"
      >
        <p
          :class="{
            ['text-warning']: hasDifferences && !(isOrderDifferenceSolved(order && order.differences) === 'all'),
            ['text-success']: isOrderDifferenceSolved(order && order.differences) === 'all',
          }"
        >
          {{ amount }}
        </p>
      </template>
      <template #[`cell-${ORDER_TABLE_HEADER.DETAILS}`]="{ rowData: { [ORDER_TABLE_HEADER.DETAILS]: details, order } }">
        <div>
          <span v-if="isOrderDifferenceSolved(order && order.differences) === 'all'">
            <span>
              {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifference') }}
            </span>
          </span>
          <span v-if="isOrderDifferenceSolved(order && order.differences) === 'partial'">
            <span>
              {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifferencePartial') }}
            </span>
          </span>
          <span v-if="isOrderDifferenceSolved(order && order.differences) !== 'all'">
            {{ details }}
          </span>
        </div>
      </template>
    </Table>
    <Table
      v-if="activeTab === 1"
      v-loading="loading"
      :columns="deliveryColumns"
      :data="deliveryData"
      custom-class="activity-table"
      show-index
      expandable
      small
      @row-click="handleRowClick"
    >
      <template #[`cell-${DELIVERY_TABLE_HEADER.DATE}`]="{ rowData: { [DELIVERY_TABLE_HEADER.DATE]: date } }">
        <p>{{ formatDate(date) }}</p>
      </template>
      <template
        #[`cell-${DELIVERY_TABLE_HEADER.DOCUMENTS}`]="{ rowData: { [DELIVERY_TABLE_HEADER.DOCUMENTS]: documents } }"
      >
        <template v-if="documents[0].id">
          <Button
            v-for="doc in documents"
            :key="doc.id"
            type="link"
            class="p-0 m-0"
            @click.stop="$emit('open-document', { documentId: doc.id, documentsIds: getDocumentsIds(deliveries) })"
          >
            {{ $t(`document.exports.schema.type.shortName.${doc.type}`) }} {{ doc.documentNumber }}
          </Button>
        </template>
        <p v-else class="text-warning">{{ getMissingType(documents) }}</p>
      </template>
      <template
        #[`cell-${DELIVERY_TABLE_HEADER.PRODUCT_COUNT}`]="{
          rowData: { [DELIVERY_TABLE_HEADER.PRODUCT_COUNT]: productCount, hasDifferences, hasImbalances, diffs },
        }"
      >
        <p
          :class="{
            ['text-warning']: hasDifferences && !(isDeliveryDifferenceSolved(diffs) === 'all'),
            ['text-danger']: hasImbalances,
            ['text-success']: isDeliveryDifferenceSolved(diffs) === 'all',
          }"
        >
          {{ productCount }}
        </p>
      </template>

      <template
        #[`cell-${DELIVERY_TABLE_HEADER.DETAILS}`]="{
          rowData: { details, documents, parentDocumentText, diffs, hasDifferences },
        }"
      >
        <div>
          <span v-if="isDeliveryDifferenceSolved(diffs) === 'all'">
            <span>
              {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifference') }}
            </span>
          </span>
          <span v-if="hasDifferences && isDeliveryDifferenceSolved(diffs) === 'partial'">
            <span>
              {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifferencePartial') }}
            </span>
          </span>
          <span v-if="isDeliveryDifferenceSolved(diffs) !== 'all'">
            {{ details }}
          </span>
        </div>
        <template v-if="documents[0].parentDocument">
          (
          <Button
            type="link"
            class="p-0 text-typography-primary"
            @click.stop="$emit('open-document', { documentId: documents[0].parentDocument.id })"
          >
            {{ parentDocumentText }}
          </Button>
          )
        </template>
      </template>
      <template #cell-actions="{ rowData: { missingEventId }, rowIndex }">
        <el-dropdown
          v-if="isAdmin && missingEventId"
          class="d-flex justify-content-center"
          trigger="click"
          placement="bottom"
          @command="handleAction($event, missingEventId)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="text"
            class="p-0 action-button hover-btn text-typography-primary"
            :class="{ active: activeActions === rowIndex }"
            @click.stop
          >
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item
              v-if="isAdmin && missingEventId"
              :disabled="!isAdmin"
              :command="ACTION.RESOLVE_MISSING_EVENT"
            >
              {{ $t('billing.billingManagement.expandableActivity.actions.resolveMissingEvent') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <Table
      v-if="activeTab === 2"
      v-loading="loading"
      :columns="billingColumns"
      :data="billingData"
      custom-class="activity-table"
      show-index
      expandable
      small
      @row-click="handleRowClick"
    >
      <template #[`cell-${BILLING_TABLE_HEADER.DATE}`]="{ rowData: { [BILLING_TABLE_HEADER.DATE]: date } }">
        <p>{{ formatDate(date) }}</p>
      </template>
      <template
        #[`cell-${BILLING_TABLE_HEADER.DOCUMENTS}`]="{ rowData: { [BILLING_TABLE_HEADER.DOCUMENTS]: documents, type } }"
      >
        <span v-if="type === 'balance'">{{ $t('billing.billingManagement.expandableActivity.balanceBilling') }}</span>
        <template v-if="documents[0].id">
          <Button
            v-for="doc in documents"
            :key="doc.id"
            type="link"
            class="p-0"
            @click.stop="$emit('open-document', { documentId: doc.id, documentsIds: getDocumentsIds(billings) })"
          >
            {{ $t(`document.exports.schema.type.shortName.${doc.type}`) }} {{ doc.documentNumber }}
          </Button>
        </template>
        <p v-else class="text-warning">{{ getMissingType(documents) }}</p>
      </template>

      <template #[`cell-${BILLING_TABLE_HEADER.STATUS}`]="{ rowData: { [BILLING_TABLE_HEADER.STATUS]: status } }">
        <Status :status="status" />
      </template>

      <template
        #[`cell-${BILLING_TABLE_HEADER.DETAILS}`]="{
          rowData: {
            id,
            documents,
            parentDocumentText,
            imbalances,
            isAllImbalancesResolved,
            hasRelatedBillings,
            supplierId,
            status,
          },
        }"
      >
        <template v-if="imbalances.length && status !== RECONCILIATION_STATUSES.WAITING_FOR_MATCH">
          <p v-if="isAllImbalancesResolved">
            {{ `[${$t('billing.billingManagement.expandableActivity.details.billing.imbalanceResolved')}]` }}
            <span class="text-decoration-line-through">{{
              $tc('billing.billingManagement.details.imbalance', imbalances.length)
            }}</span>
          </p>
          <Button
            v-else
            type="link"
            class="p-0 text-typography-primary"
            @click.stop="$emit('open-issues', { billingId: id, supplierId })"
          >
            <span v-if="hasRelatedBillings">{{
              `[${$t('billing.billingManagement.expandableActivity.details.billing.imbalanceResolvedPartially')}]`
            }}</span>
            {{ $tc('billing.billingManagement.details.imbalance', imbalances.length) }}
          </Button>
        </template>
        <template
          v-else-if="
            imbalances.length && !isAllImbalancesResolved && status === RECONCILIATION_STATUSES.WAITING_FOR_MATCH
          "
        >
          <Button
            type="link"
            class="p-0 text-typography-primary"
            @click.stop="$emit('open-credit-match', { billingId: id, supplierId })"
          >
            {{ $t('billing.billingManagement.details.notMatched') }}
          </Button>
        </template>
        <template v-else-if="documents[0].parentDocument">
          (
          <Button
            type="link"
            class="p-0 text-typography-primary"
            @click.stop="$emit('open-document', { documentId: documents[0].parentDocument.id })"
          >
            {{ parentDocumentText }}
          </Button>
          )
        </template>
        <template v-else-if="!documents[0].id">
          {{ $t('billing.billingManagement.expandableActivity.details.missing') }}
        </template>
      </template>
      <template #cell-actions="{ rowData: { missingEventId }, rowIndex }">
        <el-dropdown
          v-if="isAdmin && missingEventId"
          class="d-flex justify-content-center"
          trigger="click"
          placement="bottom"
          @command="handleAction($event, missingEventId)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="text"
            class="p-0 action-button hover-btn text-typography-primary"
            :class="{ active: activeActions === rowIndex }"
            @click.stop
          >
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item
              v-if="isAdmin && missingEventId"
              :disabled="!isAdmin"
              :command="ACTION.RESOLVE_MISSING_EVENT"
            >
              {{ $t('billing.billingManagement.expandableActivity.actions.resolveMissingEvent') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
  </div>
</template>

<script>
import { isNil, uniq, pipe, reject, flatten } from 'ramda';

import { Tabs, Table, Button } from '@/modules/core';
import { DOCUMENT_TYPES, NOTE_DOCS, CONSOLIDATED_DOCS, INVOICE_DOCS } from '@/modules/document/types';
import { options } from '@/locale/dateConfig';
import { NoticeIcon, KebabIcon } from '@/assets/icons';
import { RECONCILIATION_STATUSES } from '@/modules/reconciliation';
import { useCurrency } from '@/modules/core/compositions/money-currency';

const dateSort = (a, b) => new Date(a.date) - new Date(b.date);
const ORDER_TABLE_HEADER = {
  DATE: 'date',
  DOCUMENTS: 'documents',
  PRODUCT_COUNT: 'productCount',
  TOTAL_NET_AMOUNT: 'amount',
  DETAILS: 'details',
};

const DELIVERY_TABLE_HEADER = {
  DATE: 'date',
  DOCUMENTS: 'documents',
  PRODUCT_COUNT: 'productCount',
  DETAILS: 'details',
  ACTIONS: 'actions',
};

const BILLING_TABLE_HEADER = {
  DATE: 'date',
  DOCUMENTS: 'documents',
  TOTAL_NET_AMOUNT: 'netAmount',
  TAX_AMOUNT: 'taxAmount',
  TOTAL_AMOUNT: 'totalAmount',
  STATUS: 'status',
  DETAILS: 'details',
  ACTIONS: 'actions',
};

const TABS = {
  ORDER: 'order',
  DELIVERY: 'delivery',
  BILLING: 'billing',
};

const ACTION = {
  RESOLVE_MISSING_EVENT: 'resolveMissingEvent',
};

const COLUMNS_WIDTH = '140px';
const COLUMNS_DOCUMENT_NUMBER_WIDTH = '180px';
const COLUMNS_DATE_WIDTH = '90px';

export default {
  components: {
    Tabs,
    Table,
    KebabIcon,
    Button,
    Status: () => import('@/modules/reconciliation/reconciliationStatus/Status'),
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    reconciliationsStatus: { type: Object, default: null },
    loading: { type: Boolean, default: false },
    billings: { type: Array, default: null },
    deliveries: { type: Array, default: null },
    missing: { type: Array, default: () => [] },
    orders: { type: Array, default: null },
  },
  setup() {
    const { formatToCurrency, formatCentsToCurrency } = useCurrency();

    return { formatCentsToCurrency, formatToCurrency };
  },
  data() {
    let activeTab = 0;
    if (this.billings?.length) activeTab = 2;
    else if (this.deliveries?.length) activeTab = 1;

    return {
      ACTION,
      RECONCILIATION_STATUSES,
      ORDER_TABLE_HEADER,
      DELIVERY_TABLE_HEADER,
      BILLING_TABLE_HEADER,
      activeTab,
      activeActions: null,
    };
  },
  computed: {
    tabs() {
      const tabsToShow = [];
      if (!isNil(this.orders)) tabsToShow.push(TABS.ORDER);
      if (!isNil(this.deliveries)) tabsToShow.push(TABS.DELIVERY);
      if (!isNil(this.billings)) tabsToShow.push(TABS.BILLING);
      return tabsToShow.map((tabType) => {
        return {
          text: this.$t(`billing.billingManagement.expandableActivity.tab.${tabType}`),
          ...this.getTabIcon(tabType),
        };
      });
    },
    orderColumns() {
      return [
        {
          header: this.$t('billing.billingManagement.commonTable.date'),
          key: ORDER_TABLE_HEADER.DATE,
          width: COLUMNS_DATE_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.documentNumber'),
          key: ORDER_TABLE_HEADER.DOCUMENTS,
          width: COLUMNS_DOCUMENT_NUMBER_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.productCount'),
          key: ORDER_TABLE_HEADER.PRODUCT_COUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.netAmount'),
          key: ORDER_TABLE_HEADER.TOTAL_NET_AMOUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.details'),
          key: ORDER_TABLE_HEADER.DETAILS,
        },
      ];
    },
    orderData() {
      const { DATE, DOCUMENTS, PRODUCT_COUNT, TOTAL_NET_AMOUNT, DETAILS } = ORDER_TABLE_HEADER;
      return isNil(this.orders) || this.loading
        ? []
        : this.orders
            .map((order) => ({
              id: order.id,
              [DATE]: order.date,
              [DOCUMENTS]: order.documents ?? [],
              [PRODUCT_COUNT]: order.itemsCount.ordered + order.itemsCount.returnNotice,
              [TOTAL_NET_AMOUNT]: this.formatToCurrency(order.netAmount) ?? '-',
              [DETAILS]: order.differences?.length
                ? this.$tc(
                    'billing.billingManagement.expandableActivity.details.order.difference',
                    order.differences.length,
                    {
                      count: order.differences.filter((d) => !d.quantity?.solved).length,
                      amount:
                        this.formatToCurrency(
                          order.differences
                            .filter((d) => !d.quantity?.solved)
                            .reduce((total, diff) => total + diff.amount, 0)
                        ) ?? '-',
                    }
                  )
                : null,
              hasDifferences: Boolean(order.differences?.length),
              toValidate: order.toValidate,
              order,
            }))
            .sort(dateSort);
    },
    deliveryColumns() {
      return [
        {
          header: this.$t('billing.billingManagement.commonTable.date'),
          key: DELIVERY_TABLE_HEADER.DATE,
          width: COLUMNS_DATE_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.documentNumber'),
          key: DELIVERY_TABLE_HEADER.DOCUMENTS,
          width: COLUMNS_DOCUMENT_NUMBER_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.productCount'),
          key: DELIVERY_TABLE_HEADER.PRODUCT_COUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.details'),
          key: DELIVERY_TABLE_HEADER.DETAILS,
        },
        {
          header: '',
          key: DELIVERY_TABLE_HEADER.ACTIONS,
          width: '40px',
        },
      ];
    },
    deliveryData() {
      const { DATE, DOCUMENTS, PRODUCT_COUNT, DETAILS } = DELIVERY_TABLE_HEADER;
      const missingDeliveries = this.missing
        .filter(({ possibleTypes }) => possibleTypes.some((type) => NOTE_DOCS.includes(type)))
        .map((missingEvent) => ({
          missingEventId: missingEvent.id,
          date: missingEvent.context.issueDate,
          products: missingEvent.context.items,
          documents: [
            {
              type: missingEvent.possibleTypes,
              documentNumber: missingEvent.reference,
              parentDocument: missingEvent.parentDocument,
            },
          ],
          diffs: [],
          imbalances: [],
        }));
      return isNil(this.deliveries) || this.loading
        ? []
        : [...this.deliveries, ...missingDeliveries]
            .map((delivery) => {
              let details = null;

              if (!delivery.documents[0].id) {
                details = this.$t('billing.billingManagement.expandableActivity.details.missing');
              }

              if (delivery.documents[0].id && delivery.diffs?.filter((d) => !d.resolved).length)
                details = this.$tc(
                  'billing.billingManagement.expandableActivity.details.delivery.difference',
                  delivery.diffs.filter((d) => !d.resolved).length,
                  {
                    count: delivery.diffs.filter((d) => !d.resolved).length,
                  }
                );

              return {
                id: delivery.id,
                missingEventId: delivery.missingEventId,
                [DATE]: delivery.date,
                [DOCUMENTS]: delivery.documents,
                [PRODUCT_COUNT]: delivery.products?.length ?? 0,
                [DETAILS]: details,
                parentDocumentText: this.getParentDocumentTextWithContext(delivery.documents[0].parentDocument),
                hasDifferences: Boolean(delivery.diffs?.length),
                diffs: delivery.diffs,
                hasImbalances: Boolean(delivery.imbalances.length),
                toValidate: delivery.toValidate,
              };
            })
            .sort(dateSort);
    },
    billingColumns() {
      return [
        {
          header: this.$t('billing.billingManagement.commonTable.date'),
          key: BILLING_TABLE_HEADER.DATE,
          width: COLUMNS_DATE_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.documentNumber'),
          key: BILLING_TABLE_HEADER.DOCUMENTS,
          width: COLUMNS_DOCUMENT_NUMBER_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.netAmount'),
          key: BILLING_TABLE_HEADER.TOTAL_NET_AMOUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.taxAmount'),
          key: BILLING_TABLE_HEADER.TAX_AMOUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.totalAmount'),
          key: BILLING_TABLE_HEADER.TOTAL_AMOUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.status'),
          key: BILLING_TABLE_HEADER.STATUS,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.details'),
          key: BILLING_TABLE_HEADER.DETAILS,
        },
        {
          header: '',
          key: BILLING_TABLE_HEADER.ACTIONS,
          width: '40px',
        },
      ];
    },
    billingData() {
      const { DATE, DOCUMENTS, TOTAL_NET_AMOUNT, TAX_AMOUNT, TOTAL_AMOUNT, STATUS } = BILLING_TABLE_HEADER;
      const missingBillings = this.missing
        .filter(({ possibleTypes }) =>
          possibleTypes.some((type) =>
            [...CONSOLIDATED_DOCS, ...INVOICE_DOCS, DOCUMENT_TYPES.INVOICE_RECEIPT].includes(type)
          )
        )
        .map((missingEvent) => ({
          missingEventId: missingEvent.id,
          date: missingEvent.context.issueDate,
          products: missingEvent.context.items,
          documents: [
            {
              type: missingEvent.possibleTypes,
              documentNumber: missingEvent.reference,
              parentDocument: missingEvent.parentDocument,
            },
          ],
          diffs: [],
          imbalances: [],
        }));

      return isNil(this.billings) || this.loading
        ? []
        : [...this.billings, ...missingBillings]
            .map((billing) => {
              return {
                id: billing.id,
                missingEventId: billing.missingEventId,
                [DATE]: billing.date,
                [DOCUMENTS]: billing.documents,
                [TOTAL_NET_AMOUNT]: this.formatCentsToCurrency(billing.netAmount) ?? this.$t('commons.unknown'),
                [TAX_AMOUNT]: this.formatCentsToCurrency(billing.taxAmount) ?? this.$t('commons.unknown'),
                [TOTAL_AMOUNT]: this.formatCentsToCurrency(billing.totalAmount) ?? this.$t('commons.unknown'),
                [STATUS]: this.getBillingStatus(billing),
                parentDocumentText: this.getParentDocumentText(billing.documents[0].parentDocument),
                imbalances: billing.imbalances,
                isAllImbalancesResolved:
                  billing.imbalances.length && billing.imbalances.every(({ resolved }) => resolved),
                hasRelatedBillings: this.getRelatedBillingIds(billing).length,
                supplierId: billing.supplierId,
                type: billing.type,
              };
            })
            .sort(dateSort);
    },
  },
  methods: {
    getBillingStatus(billing) {
      if (!billing.id) return RECONCILIATION_STATUSES.PENDING;
      const detailsObj = this.reconciliationsStatus?.details
        .find(({ key }) => key === 'billings')
        .details.find(({ key }) => key === billing.id);

      if (detailsObj) {
        return detailsObj.status;
      } else {
        if (billing.imbalances.every(({ resolved }) => resolved)) return RECONCILIATION_STATUSES.APPROVED;
        if (billing.netAmount < 0) return RECONCILIATION_STATUSES.WAITING_FOR_MATCH;
        return RECONCILIATION_STATUSES.NOT_APPROVED;
      }
    },
    getRelatedBillingIds(billing) {
      return billing
        ? uniq(flatten(billing.imbalances.map(({ billedAmounts }) => billedAmounts.map(({ id }) => id)))).filter(
            (id) => id !== billing.id
          )
        : [];
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    closeActions() {
      if (this.activeActions !== null) {
        document.getElementById(`actions-row-${this.activeActions}`).click();
        this.activeActions = null;
      }
    },
    handleAction(command, missingEventId) {
      switch (command) {
        case ACTION.RESOLVE_MISSING_EVENT:
          this.$emit('resolve-missing-event', missingEventId);
          break;
        default:
          break;
      }
    },
    getDocumentsIds(events) {
      const eventsClone = [...events];
      eventsClone.sort(dateSort);
      const docIds = flatten(eventsClone.map(({ documents }) => (documents || []).map(({ id }) => id)));
      return pipe(reject(isNil), uniq)(docIds);
    },
    getParentDocumentTextWithContext(parentDocument) {
      if (!parentDocument) return null;
      const documentDateText = new Date(parentDocument.issueDate).toLocaleDateString(this.$i18n.locale, {
        month: 'long',
        year: '2-digit',
      });
      const documentTypeText = this.$t(`document.exports.schema.type.shortName.${parentDocument.type}`);
      const parentDocumentText = `${this.$t('commons.referencedWithin')}: ${documentTypeText} ${
        parentDocument.documentNumber
      }`;
      return `${parentDocumentText} - ${documentDateText}`;
    },
    getParentDocumentText(parentDocument) {
      if (!parentDocument) return null;
      const documentDateText = new Date(parentDocument.issueDate).toLocaleDateString(this.$i18n.locale, {
        month: 'long',
        year: '2-digit',
      });
      const documentTypeText = this.$t(`document.exports.schema.type.shortName.${parentDocument.type}`);
      return `${this.$t('commons.reference')}: ${documentTypeText} ${documentDateText}`;
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleDateString(this.$i18n.locale, options.short) : '-';
    },
    getTabIcon(type) {
      const warringPrefix = {
        prefixIcon: NoticeIcon,
        prefixClass: 'text-warning',
      };
      const errorPrefix = {
        prefixIcon: NoticeIcon,
        prefixClass: 'text-danger',
      };
      const greyPrefix = {
        prefixIcon: NoticeIcon,
        prefixClass: 'text-grey',
      };

      const missingDeliveriesCount = this.missing.filter(({ possibleTypes }) =>
        possibleTypes.some((type) => NOTE_DOCS.includes(type))
      ).length;

      switch (type) {
        case TABS.ORDER:
          return this.orders.some((order) => order.differences?.length) ? warringPrefix : null;
        case TABS.DELIVERY:
          if (this.deliveries.some((delivery) => delivery.imbalances.length)) return errorPrefix;
          if (this.deliveries.some((delivery) => delivery.diffs?.length) || missingDeliveriesCount > 0)
            return warringPrefix;
          return null;
        case TABS.BILLING: {
          const combinedBillingStatus = this.billings.map((b) => this.getBillingStatus(b));
          if (combinedBillingStatus?.some((status) => status === RECONCILIATION_STATUSES.PENDING)) {
            return greyPrefix;
          }
          if (combinedBillingStatus?.some((status) => status === RECONCILIATION_STATUSES.NOT_APPROVED)) {
            return errorPrefix;
          }
          return null;
        }
        default:
          return null;
      }
    },
    getMissingType(documents) {
      return `${documents[0].type
        .map((type) => this.$t(`document.exports.schema.type.shortName.${type}`))
        .join(' / ')} ${documents[0].documentNumber ? documents[0].documentNumber : ''}`;
    },
    handleRowClick(index) {
      switch (this.activeTab) {
        case 0: {
          const id = this.orderData[index].id;
          if (this.orderData[index].order.toValidate) this.$emit('open-order-diffs-match', this.orderData[index].order);
          else if (id) this.$emit('open-activity', { id, type: 'order' });
          break;
        }
        case 1: {
          const id = this.deliveryData[index].id;
          if (this.deliveryData[index].toValidate) break;
          if (id) this.$emit('open-activity', { id, type: 'delivery' });
          break;
        }
        case 2: {
          const id = this.billingData[index].id;
          if (id) this.$emit('open-activity', { id, type: 'billing' });
          break;
        }
        default:
          break;
      }
    },
    isDeliveryDifferenceSolved(differences) {
      if (differences && differences.length === 0) return false;
      const unresolvedDifferences = differences?.filter((diff) => diff.resolved != true);
      const resolvedDifferences = differences?.filter((diff) => diff.resolved === true);
      if (unresolvedDifferences && unresolvedDifferences.length === 0) {
        return 'all';
      }
      if (resolvedDifferences && resolvedDifferences.length > 0 && differences?.length > 0) {
        return 'partial';
      }
    },
    isOrderDifferenceSolved(differences) {
      if (differences && differences.length === 0) return false;
      const unresolvedDifferences = differences?.filter(
        (diff) => diff.quantity?.solved !== true && diff.netPrice?.solved !== true
      );
      const resolvedDifferences = differences?.filter(
        (diff) => diff.quantity?.solved === true || diff.netPrice?.solved === true
      );

      if (unresolvedDifferences && unresolvedDifferences.length === 0) {
        return 'all';
      }
      if (resolvedDifferences && resolvedDifferences.length > 0 && differences?.length > 0) {
        return 'partial';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.bg-expandable {
  background: #f6f7f9;
}

::v-deep .activity-table {
  th,
  td {
    background: #f6f7f9;
  }
}

::v-deep .text-grey {
  color: #9295a5;
}

.activity-table {
  tr {
    .action-button {
      visibility: hidden;
    }

    &:hover .action-button {
      visibility: visible;
    }

    .hover-btn {
      &:hover {
        background: $secondary;
      }
      &.active {
        visibility: visible;
      }
    }
  }
}
::v-deep .orders tr.to-validate:hover {
  cursor: pointer !important;
}
</style>
